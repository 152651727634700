import * as THREE from 'three';

import Core from './Core';
import Event from './Event';


export default class HrefHandler {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();

        this.dataList = undefined;
        this.jumpToMenuKey = undefined;
        this.originUrl = undefined;

        // NOTE-240705      실행 초반 부분
        this.event.on(this.event.PROJECT_DATA_RECEIVED, (dataList) => {
            this.originUrl = `${window.location.protocol}//${window.location.host}`;
            this.dataList = dataList;
        });
        this.event.on(this.event.URL_CHECK_FINISHED, (params) => {
            if (this.core.jumpToMenu) {
                this.jumpToMenuKey = params.hash;
            }
            else if (this.core.jumpToProject) {
                const res = this.core.findProjectUrl(params.hash);
                if (res.url != undefined) {
                    this.event.trigger(this.event.PROJECTITEM_CLICKED_WRAPPER, [res.title]);
                }
                else {
                    // NOTE-240705      Core.js에서 확인하기 때문에 여기에 들어올 일은 없어야함
                    this.resetUrl();
                    console.error('how to run this section?');
                }
            }
            else {
                this.resetUrl();
            }
        });
        this.event.on(this.event.KV_CLOSED, () => {
            if (this.core.jumpToMenu) {
                if (this.jumpToMenuKey === 'projectList') {
                    this.event.trigger(this.event.UI_SWITCHBUTTON_CLICKED_WRAPPER, [true]);
                }
                else {
                    this.event.trigger(this.event.UI_MENUBUTTON_CLICKED_WRAPPER, [this.jumpToMenuKey, true]);
                }
            }
        });
        // //

        // NOTE-240705      프로젝트 상세 관련
        this.event.on(this.event.PROJECTITEM_CLICKED, (params) => {
            this.onProjectItemClicked(params);
        });
        this.event.on(this.event.MENU2D_PROJECT_CLICKED, (title) => {
            this.onProjectItemClicked({ title: title });
        });
        this.event.on(this.event.REQUEST_PROJECTDETAIL_IFRAME_DISABLE, () => {
            this.resetUrl();
        });
        this.event.on(this.event.REQUEST_PROJECTLIST_IFRAME_DISABLE, () => {
            this.resetUrl();
        });
        this.event.on(this.event.MENU2D_URL_CHANGED, (url) => {
            if (url === this.core.urlContainer.projectListUrl) {
                this.resetUrl();
            }
        });
        // //

        // NOTE-240705      메뉴 관련
        this.event.on(this.event.UI_MENUBUTTON_CLICKED, (key) => {
            this.changeUrlForMenu(key);
        });
        this.event.on(this.event.UI_MENUBACKBUTTON_CLICKED, (key) => {
            this.resetUrl();
        });
        // //

        // NOTE-240705      리스트뷰쪽 관련
        this.event.on(this.event.UI_SWITCHBUTTON_CLICKED, () => {
            this.changeUrlForMenu('projectList');
            //this.resetUrl();
        });
        this.event.on(this.event.UI_SWITCHBUTTON_CLICKED_FROM_MENU, () => {
            this.changeUrlForMenu('projectList');
            //this.resetUrl();
        });
        this.event.on(this.event.UI_SWITCHBUTTON_CLICKED_FROM_MENU2D, () => {
            this.changeUrlForMenu('projectList');
            //this.resetUrl();
        });
        this.event.on(this.event.MENU2D_ABOUT_CLICKED, () => {
            this.changeUrlForMenu('about');
        });
        this.event.on(this.event.MENU2D_CONTACT_CLICKED, () => {
            this.changeUrlForMenu('contact');
        });
        this.event.on(this.event.MENU2D_EXPERTISE_CLICKED, () => {
            this.changeUrlForMenu('expertise');
        });
        // //
    }

    changeUrlForMenu(key) {
        history.replaceState(null, null, '#' + key);
    }

    onProjectItemClicked(params) {
        if (window.location.hash != ''
            && window.location.hash.includes('projectList') === false)
            return;

        const tempTitle = params.title.replace(/ /g, '_');
        history.replaceState(null, null, '#' + tempTitle);
    }

    resetUrl() {
        history.replaceState(null, null, this.originUrl);
    }
}