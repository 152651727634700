import Core from '../Core';
import Event from '../Event';

export default class ContactCTA {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();

        // NOTE-241226      MD단에서 Contact Us 눌렀을 때
        this.event.on(this.event.GO_TO_CONTACT, (fromPage) => {
            this.onCTAClicked();
        });
        // //

        return;

        this.awake();

        // NOTE-241218      돌아다니는 메뉴버튼 눌렀을 때
        this.event.on(this.event.UI_MENUBUTTON_CLICKED_WRAPPER, () => {
            this.showCTA();
        });
        this.event.on(this.event.UI_MENUBACKBUTTON_CLICKED_WRAPPER, () => {
            this.hideCTA();
        });
        // //

        // NOTE-241218      프로젝트 상세 페이지 열었을 때
        this.event.on(this.event.REQUEST_PROJECTDETAIL_IFRAME_ENABLE, () => {
            this.showCTA();
        });
        this.event.on(this.event.REQUEST_PROJECTDETAIL_IFRAME_DISABLE, () => {
            this.hideCTA();
        });
        // //

        // NOTE-241218      프로젝트 리스트 페이지에서 메뉴 버튼 눌렀을 때
        this.event.on(this.event.MENU2D_ABOUT_CLICKED, () => {
            this.showCTA();
        });
        this.event.on(this.event.MENU2D_EXPERTISE_CLICKED, () => {
            this.showCTA();
        });
        this.event.on(this.event.MENU2D_CONTACT_CLICKED, () => {
            this.hideCTA();
        });
        // //

        // NOTE-241218      프로젝트 리스트 2D 전환 버튼 눌렀을 때
        this.event.on(this.event.UI_SWITCHBUTTON_CLICKED_WRAPPER, () => {
            this.showCTA();
        });
        this.event.on(this.event.UI_SWITCHBACKBUTTON_CLICKED_WRAPPER, () => {
            this.hideCTA();
        });
        // //

        // NOTE-241218      프로젝트 링 클릭했을 때
        this.event.on(this.event.PROJECTITEM_CLICKED_WRAPPER, () => {
            this.showCTA();
        });
        // //
    }

    awake() {
        this.ctaButton = document.getElementById('contactCTA-button');
        this.ctaButton.onmouseover = function () {
            this.onCTAHovered();
        }.bind(this);
        this.ctaButton.onmouseout = function () {
            this.onCTAHoverOuted();
        }.bind(this);
        this.ctaButton.onclick = function () {
            this.onCTAClicked();
        }.bind(this);

        this.ctaTextArea = document.getElementById('contactCTA-textArea');
    }

    showCTA() {
        if (this.core.stateController.currentMenu === 'MENU_contact') {
            this.hideCTA();
            return;
        }

        this.ctaButton.classList.add('show');
    }

    hideCTA() {
        this.ctaButton.classList.remove('show');
        this.onCTAHoverOuted();
    }

    onCTAHovered() {
        if (this.core.config.isMobile === false)
            this.ctaTextArea.classList.add('show');
        else
            this.ctaTextArea.classList.remove('show');

        if (this.core.stateController.currentMenu != undefined
            && this.core.stateController.currentMenu.includes('MENU')) {
            this.ctaButton.classList.add('effect');
        }
    }

    onCTAHoverOuted() {
        this.ctaTextArea.classList.remove('show');
        this.ctaButton.classList.remove('effect');
    }

    onCTAClicked() {
        if (this.core.stateController.currentMenu == undefined) {
            if (this.core.dom.iframeProjectDetail.isShowing) {
                this.event.trigger(this.event.UI_MENUBACKBUTTON_CLICKED_WRAPPER);
                this.event.trigger(this.event.UI_MENUBUTTON_CLICKED_WRAPPER, ['contact', true]);
            }
        }
        else if (this.core.stateController.currentMenu.includes('MENU2D')
            || this.core.stateController.currentMenu.includes('LISTVIEW')) {
            this.event.trigger(this.event.MENU2D_CONTACT_CLICKED);
        }
        else if (this.core.stateController.currentMenu.includes('MENU')) {
            this.event.trigger(this.event.UI_MENU_URL_CHANGED, [this.core.urlContainer.contactUrl]);
            //this.hideCTA();  // NOTE-250131       나중에 contact us CTA 버튼 생기면 부활할 것
        }
    }

}