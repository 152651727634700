import * as THREE from 'three';
import gsap from 'gsap';

import Core from "../Core";
import Event from "../Event";


export default class KvController {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();
        this.config = this.core.config;
        this.structure = this.core.structure;

        this.awake();

        this.event.on(this.event.STRUCTURE_MOTION_FINISHED, (isSkipped) => {
            if (this.core.jumpToProject || this.core.jumpToMenu) {
                this.quickDeactivate();
                return;
            }

            if (isSkipped)
                this.quickActivate();
            else
                setTimeout(() => this.activate(), 500);
        });
    }

    awake() {
        // NOTE-240529      KV 영상 경로
        const root = this.core.rootFilePath;
        this.kvList = [];

        // this.kvList.push({
        //     showTitle: 'MEDITATION VR APP ORBI',
        //     path: `${root}/videos/XR_meditation_app_trailer_t1_sq.mp4`,
        //     title: 'Meditation VR App ORBI'
        // });
        this.kvList.push({
            showTitle: 'SAMSUNG SEMICONDUCTOR SMART KIOSK',
            path: `${root}/videos/00_CES_showcase_t3_sq.mp4`,
            title: 'Samsung Semiconductor Smart Kiosk'
        });
        this.kvList.push({
            showTitle: 'HD HYUNDAI RECRUITMENT PLATFORM',
            path: `${root}/videos/HD_showcase_t3_square.mp4`,
            title: 'HD Hyundai Recruitment Platform'
        });
        this.kvList.push({
            showTitle: 'HD KSOE EP DIVISION WEBSITE',
            path: `${root}/videos/showcase_HD_t5.mp4`,
            title: 'HD KSOE EP Division Website'
        });
        this.kvList.push({
            showTitle: 'BANBANPLAY APPLICATION',
            path: `${root}/videos/award_banban_t2_square.mp4`,
            title: 'BANBANPLAY Application'
        });
        this.kvList.push({
            showTitle: 'CU FRANCHISE PROMOTION SITE',
            path: `${root}/videos/CU_owner_t1_KV.mp4`,
            title: 'CU franchise promotion site'
        });
        this.kvList.push({
            showTitle: 'YUTNOLEE',
            path: `${root}/videos/main_kv_yutnolee.mp4`,
            title: 'Samsung Throw the Yut'
        });
        this.kvList.push({
            showTitle: 'SEAH GROUP AFFILIATES',
            path: `${root}/videos/main_kv_seah.mp4`,
            title: 'SeAH Group Affiliates'
        });
        this.kvList.push({
            showTitle: 'BGF RETAIL',
            path: `${root}/videos/kv_bgf.mp4`,
            title: 'BGF Retail'
        });
        this.kvList.push({
            showTitle: 'SAMSUNG SEMICONDUCTOR SITE',
            path: `${root}/videos/kv_semiconductor.mp4`,
            title: 'Samsung Semiconductor Site'
        });
        // //

        this.currentIndex = 0;
        this.enabled = false;
        this.currentAnimTime = 0;

        this.container = document.getElementById('kv-container');
        this.kvInside = document.getElementById('kv-inside');
        this.kvOutside = document.getElementById('kv-outside');
        this.kvVideo = document.getElementById('kv-video');
        this.kvSource = document.getElementById('kv-video-source');
        this.kvVideo.addEventListener('ended', () => {
            if (this.enabled)
                this.swipeVideo(false);
        });

        // NOTE-240531      프로젝트 타이틀과 같은것 공유
        this.kvTitle = document.getElementById('project-title-text');

        // NOTE-240529      좌우 버튼
        const kvVideoSwipeButtons = document.getElementsByClassName('kv-video-swipeButton');
        for (let i = 0; i < kvVideoSwipeButtons.length; ++i) {
            const key = kvVideoSwipeButtons[i].getAttribute('key');
            if (key === 'left')
                this.kvVideoLeft = kvVideoSwipeButtons[i];
            else if (key === 'right')
                this.kvVideoRight = kvVideoSwipeButtons[i];
        }
        this.kvVideoLeft.onclick = () => {
            this.swipeVideo(true);
        };
        this.kvVideoLeft.onmouseover = () => {
            for (let i = 0; i < this.kvVideoLeft.children.length; ++i)
                this.kvVideoLeft.children[i].classList.add('hover');
        };
        this.kvVideoLeft.onmouseout = () => {
            for (let i = 0; i < this.kvVideoLeft.children.length; ++i)
                this.kvVideoLeft.children[i].classList.remove('hover');
        };
        this.kvVideoRight.onclick = () => {
            this.swipeVideo(false);
        };
        this.kvVideoRight.onmouseover = () => {
            for (let i = 0; i < this.kvVideoRight.children.length; ++i)
                this.kvVideoRight.children[i].classList.add('hover');
        };
        this.kvVideoRight.onmouseout = () => {
            for (let i = 0; i < this.kvVideoRight.children.length; ++i)
                this.kvVideoRight.children[i].classList.remove('hover');
        };

        for (let i = 0; i < this.kvVideoLeft.children.length; ++i) {
            const item = this.kvVideoLeft.children[i];
            item.style.left = `${10 + i * 20}%`;
        }
        for (let i = 0; i < this.kvVideoRight.children.length; ++i) {
            const item = this.kvVideoRight.children[i];
            item.style.left = `${10 + i * 20}%`;
        }
        // //

        this.currentKvRadius = undefined;
        this.resize();
        this.changeVideo(this.currentIndex, false);
    }

    resize() {
        let targetSize = Math.min(this.config.width, this.config.height);

        if (this.config.isMobile === false && this.config.width - 228 < this.config.height) {
            targetSize = this.config.height - (228 - (this.config.width - this.config.height));
            this.kvInside.style.width = this.kvInside.style.height = `${targetSize}px`;
        }
        else {
            this.kvInside.style.width = this.kvInside.style.height = `${targetSize}px`;
        }

        // NOTE-240531      좌우버튼은 크기 조절 없음
        const buttonSize = Math.max(42.4, targetSize * 0.04);

        this.kvVideoLeft.style.width = this.kvVideoLeft.style.height = `${buttonSize}px`;
        this.kvVideoRight.style.width = this.kvVideoRight.style.height = `${buttonSize}px`;

        if (this.config.isMobile) {
            this.kvVideoLeft.style.right = `${(targetSize - buttonSize) * 0.5}px`;
            this.kvVideoLeft.style.top = `${targetSize - buttonSize * 2}px`;
            this.kvVideoRight.style.right = `${(targetSize - buttonSize) * 0.5}px`;
            this.kvVideoRight.style.top = `${targetSize - buttonSize}px`;
            this.kvVideoLeft.style.transform = `rotate(90deg)`;
            this.kvVideoRight.style.transform = `rotate(-90deg)`;
        }
        else {
            this.kvVideoLeft.style.right = `${buttonSize}px`;
            this.kvVideoLeft.style.top = `${(targetSize - buttonSize) * 0.5}px`;
            this.kvVideoRight.style.right = `${0}px`;
            this.kvVideoRight.style.top = `${(targetSize - buttonSize) * 0.5}px`;
            this.kvVideoLeft.style.transform = `rotate(0deg)`;
            this.kvVideoRight.style.transform = `rotate(180deg)`;
        }

        this.currentKvRadius = targetSize * 0.5;
    }

    update() {
        if (this.enabled === false)
            return;

        this.currentAnimTime += this.core.deltaTime * 2;
        const secondAnimTime = this.currentAnimTime + THREE.MathUtils.degToRad(45);
        const firstValue = Math.abs(Math.sin(this.currentAnimTime)) * 0.35;
        const secondValue = Math.abs(Math.sin(secondAnimTime)) * 0.15;
        this.kvVideoLeft.children[1].style.opacity = `${firstValue}`;
        this.kvVideoLeft.children[2].style.opacity = `${secondValue}`;

        this.kvVideoRight.children[1].style.opacity = `${firstValue}`;
        this.kvVideoRight.children[2].style.opacity = `${secondValue}`;
    }

    activate() {
        this.structure.customOrbitControls.forIntro = true;
        this.enabled = true;
        this.currentAnimTime = 0;
        const scaleMtp = 1.52;

        if (this.scaleAnim)
            this.scaleAnim.pause();
        this.scaleAnim = gsap.to(this.structure.group.scale, {
            x: this.structure.defaultScale * scaleMtp,
            y: this.structure.defaultScale * scaleMtp,
            z: this.structure.defaultScale * scaleMtp,
            duration: 0.75
        });

        if (this.videoAnim)
            this.videoAnim.pause();
        this.videoAnim = gsap.to({ dummy: 0 }, {
            dummy: 0,
            delay: 0.35,
            duration: 0.5,
            onStart: () => {
                this.kvInside.classList.add('show');
                this.kvVideoLeft.classList.add('show');
                this.kvVideoRight.classList.add('show');
            },
            onComplete: () => {
                this.kvVideo.play();
                this.kvVideo.style.cursor = 'pointer';
                this.kvVideo.onclick = () => {
                    if (this.checkOutsideClicked(event) === false)
                        this.onVideoClicked(this.currentIndex);
                };

                this.kvInside.onclick = (event) => {
                    if (this.checkOutsideClicked(event))
                        this.deactivate();
                };
                this.kvOutside.onclick = () => {
                    this.deactivate();
                };
            }
        });

        this.kvOutside.classList.add('show');
        this.kvTitle.classList.add('show');
        this.kvTitle.style.zIndex = 2;
    }

    quickActivate() {
        if (this.scaleAnim)
            this.scaleAnim.pause();
        if (this.videoAnim)
            this.videoAnim.pause();

        this.structure.customOrbitControls.forIntro = true;
        this.enabled = true;
        this.currentAnimTime = 0;
        const scaleMtp = 1.52;

        this.structure.group.scale.setScalar(this.structure.defaultScale * scaleMtp);

        this.kvInside.classList.add('show');
        this.kvVideoLeft.classList.add('show');
        this.kvVideoRight.classList.add('show');

        this.kvVideo.play();
        this.kvVideo.style.cursor = 'pointer';
        this.kvVideo.onclick = (event) => {
            if (this.checkOutsideClicked(event) === false)
                this.onVideoClicked(this.currentIndex);
        };

        this.kvInside.onclick = (event) => {
            if (this.checkOutsideClicked(event))
                this.deactivate();
        };
        this.kvOutside.onclick = () => {
            this.deactivate();
        };

        this.kvOutside.classList.add('show');
        this.kvTitle.classList.add('show');
        this.kvTitle.style.zIndex = 2;
    }

    deactivate() {
        if (this.scaleAnim)
            this.scaleAnim.pause();

        this.scaleAnim = gsap.to(this.structure.group.scale, {
            x: this.structure.defaultScale,
            y: this.structure.defaultScale,
            z: this.structure.defaultScale,
            duration: 0.5
        });

        if (this.videoAnim)
            this.videoAnim.pause();

        this.videoAnim = gsap.to({ dummy: 0 }, {
            dummy: 0,
            duration: 0.5,
            onStart: () => {
                this.kvInside.classList.remove('show');
                this.kvVideoLeft.classList.remove('show');
                this.kvVideoRight.classList.remove('show');
            },
            onComplete: () => {
                this.kvInside.onclick = null;
                this.kvOutside.onclick = null;
                this.container.classList.add('hide');

                this.enabled = false;
                this.event.trigger(this.event.KV_CLOSED);
            }
        });

        this.kvVideo.style.cursor = 'default';
        this.kvVideo.onclick = undefined;
        this.kvVideo.pause();
        this.kvVideo.currentTime = 0;

        this.kvOutside.classList.remove('show');
        this.kvTitle.classList.remove('show');
        this.kvTitle.style.zIndex = 0;
    }

    quickDeactivate() {
        if (this.scaleAnim)
            this.scaleAnim.pause();
        if (this.videoAnim)
            this.videoAnim.pause();

        this.structure.group.scale.setScalar(this.structure.defaultScale);

        this.kvInside.classList.remove('show');
        this.kvVideoLeft.classList.remove('show');
        this.kvVideoRight.classList.remove('show');
        this.kvInside.onclick = null;
        this.kvOutside.onclick = null;
        this.container.classList.add('hide');

        this.enabled = false;
        this.event.trigger(this.event.KV_CLOSED);
        this.kvVideo.style.cursor = 'default';
        this.kvVideo.onclick = undefined;
        this.kvVideo.pause();
        this.kvVideo.currentTime = 0;

        this.kvOutside.classList.remove('show');
        this.kvTitle.classList.remove('show');
        this.kvTitle.style.zIndex = 0;
    }

    changeVideo(index, isPlay) {
        if (this.changeTimeline)
            this.changeTimeline.pause();

        this.currentIndex = index;
        this.kvTitle.innerHTML = this.kvList[index].showTitle;

        const animObj = { opacity: this.kvVideo.style.opacity };
        this.changeTimeline = gsap.timeline();
        this.changeTimeline.to(animObj, {
            opacity: 0,
            duration: 0.1,
            onUpdate: () => {
                this.kvVideo.style.opacity = animObj.opacity;
            }
        });
        this.changeTimeline.to(animObj, {
            opacity: 1,
            duration: 0.1,
            delay: 0.1,
            onStart: () => {
                this.kvSource.src = this.kvList[index].path;
                this.kvVideo.load();
                this.kvVideo.currentTime = 0;
            },
            onUpdate: () => {
                this.kvVideo.style.opacity = animObj.opacity;
            },
            onComplete: () => {
                if (isPlay)
                    this.kvVideo.play();
            }
        });
    }

    swipeVideo(isLeft) {
        let targetIndex = this.currentIndex;
        if (isLeft) {
            if (--targetIndex < 0)
                targetIndex = this.kvList.length - 1;
        }
        else {
            if (++targetIndex >= this.kvList.length)
                targetIndex = 0;
        }
        this.changeVideo(targetIndex, true);
    }

    checkOutsideClicked(event) {
        const clickedPoint = new THREE.Vector2(
            event.clientX - this.config.width * 0.5,
            event.clientY - this.config.height * 0.5
        );
        return clickedPoint.distanceTo(new THREE.Vector3()) > this.currentKvRadius;
    }

    onVideoClicked(index) {
        this.deactivate();

        if (this.kvList[index].title != undefined) {
            this.event.trigger(this.event.PROJECTITEM_CLICKED_WRAPPER, [this.kvList[index].title]);
        }
    }
}