import Core from './Core/Core.js';
import Event from './Core/Event.js';

// 로컬 개발용
//import './style_local.scss';

// 배포용
import './style_dev.scss';


const event = new Event();
const core = new Core({
    targetElement: document.querySelector('canvas.webgl')
});